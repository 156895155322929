<script setup>
import { reactive, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import types from 'dashboard/store/mutation-types';

const store = useStore();

const formValues = reactive({
  name: '',
  description: '',
  tag: null,
  category: null,
  messages: [],
});

const tags = computed(() => store.getters.getForumTags);
const isOpened = computed(() => store.getters.getForumModalStatus);
const categories = computed(() => store.getters.getForumCategories);
const chatMessages = computed(() => store.getters.getForumMessages);
const isPending = computed(() => store.getters.getForumModalRequestStatus);

const rules = computed(() => ({
  name: { required, minLength: minLength(20) },
  description: { required, minLength: minLength(30) },
}));

const v$ = useVuelidate(rules, formValues);

const resetValues = () => {
  formValues.name = '';
  formValues.description = '';
  setInitialTag();
  setInitialMessages();
  setInitialCategory();
};

const setInitialTag = () => {
  formValues.tag = tags.value[0] || null;
};

const setInitialCategory = () => {
  const productsCategory = categories.value.find(v => v === 'AEJuice Products');
  formValues.category = productsCategory || categories.value[0] || null;
};

const setInitialMessages = () => {
  formValues.messages = chatMessages.value || [];
};

const closeModal = () => {
  store.commit(types.CLOSE_FORUM_MODAL);
};

const addEmptyMessage = () => {
  formValues.messages.push({
    text: '',
    senderType: 'support',
    id: `empty-${Math.floor(Math.random() * 1e10)}`,
  });
};

const deleteMessage = id => {
  formValues.messages = formValues.messages.filter(
    message => message.id !== id
  );
};

const submitForm = () => {
  v$.value.$touch();
  if (v$.value.$invalid) return;
  store.dispatch('sendForumForm', formValues);
};

watch(tags, setInitialTag);
watch(categories, setInitialCategory);
watch(chatMessages, setInitialMessages);
watch(isOpened, nextValue => {
  if (!nextValue) {
    v$.value.$reset();
    resetValues();
  }
});

onMounted(() => {
  setInitialTag();
  setInitialMessages();
  setInitialCategory();
});
</script>

<template>
  <woot-modal v-model:show="isOpened" :on-close="closeModal" size="medium">
    <div class="column">
      <woot-modal-header header-title="Create forum topic from chat" />
      <div class="row">
        <form class="column" @submit.prevent="submitForm">
          <label :class="{ error: v$.name.$error }">
            Topic name
            <input
              v-model="formValues.name"
              type="text"
              @input="v$.name.$touch"
            />
            <span v-if="v$.name.$error" class="message">
              Topic name cannot be shorter than 20 symbols
            </span>
          </label>
          <label :class="{ error: v$.description.$error }">
            Topic description
            <textarea
              v-model="formValues.description"
              rows="3"
              @input="v$.description.$touch"
            />
            <span v-if="v$.description.$error" class="message">
              Topic description cannot be shorter than 30 symbols
            </span>
          </label>
          <label>
            Сategory
            <select v-model="formValues.category">
              <option
                v-for="option in categories"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </label>
          <label>
            Tag
            <select v-model="formValues.tag">
              <option v-for="option in tags" :key="option" :value="option">
                {{ option }}
              </option>
            </select>
          </label>
          <table v-if="formValues.messages.length" class="messages">
            <thead>
              <tr>
                <th>№</th>
                <th>Author</th>
                <th colspan="2">Message</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(message, index) in formValues.messages"
                :key="message.id"
              >
                <td>{{ index }}</td>
                <td>
                  <select v-model="message.senderType">
                    <option value="user">User</option>
                    <option value="support">Support</option>
                  </select>
                </td>
                <td>
                  <textarea v-model="message.text" rows="2" />
                </td>
                <td>
                  <woot-button
                    icon="dismiss"
                    variant="clear"
                    color-scheme="secondary"
                    @click="deleteMessage(message.id)"
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4">
                  <div class="w-full flex-container align-right">
                    <woot-button
                      icon="add"
                      size="small"
                      type="button"
                      variant="smooth"
                      color-scheme="success"
                      @click="addEmptyMessage"
                    >
                      Add new message
                    </woot-button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="w-full flex-container align-right">
            <woot-button type="submit" :is-loading="isPending">
              Send form
            </woot-button>
          </div>
        </form>
      </div>
    </div>
  </woot-modal>
</template>

<style scoped lang="scss">
.messages {
  width: 100%;
  margin: 0 0 2.6rem;
  text-align: center;
  border-collapse: collapse;

  & td {
    border: 1px solid var(--s-50);
  }

  & tr {
    vertical-align: top;
  }

  & tbody td:last-child {
    width: 40px;
  }

  & textarea,
  & select {
    margin-bottom: 0 !important;
  }

  & .actions {
    text-align: right;
  }
}
</style>
